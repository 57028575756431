import React, { useEffect, useState } from 'react';
import { useNetworkState } from 'react-use';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const Network = () => {
  const network = useNetworkState();
  const [networkData, setNetworkData] = useState<any>(null);
  const [networkHash, setNetworkHash] = useState<string | null>(null);

  useEffect(() => {
    const fetchNetworkData = async () => {
      const { since, saveData, ...stateWithoutSinceAndSaveData } = network;
      const hash = await getHash(network);

      globalStore.set({ ...globalStore.get(), network_status: { hash: hash, data: stateWithoutSinceAndSaveData } });
      setNetworkData(stateWithoutSinceAndSaveData);
      setNetworkHash(hash);
    };

    fetchNetworkData();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">Network <span className="text-sm border px-1">{networkHash?.slice(0, 12)}</span></h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={networkData} />
      </div>
    </div>
  );
};

export default Network;
